<template>
    <mercur-dialog :is-open.sync="localDialogActive" width="80%" class="fill full-height-layout">
        <h2 class="font-weight-normal mt-1">Cut off time adjustment</h2>
        <div class="full-height-layout fill">
            <div :class="`configuration configuration--indent${key}`" v-for="(field, key) in fields" :key="field.value">
                <div><label>{{field.title}}</label></div>
                <div class="configuration__field">
                    <mercur-radio class="m-2" :data-test="`${field.selectLabel}SpecifyingFieldsFalse`" v-model="specifyingFields[field.value]" :value="false">All</mercur-radio>
                    <mercur-radio class="m-2" :data-test="`${field.selectLabel}SpecifyingFieldsTrue`" v-model="specifyingFields[field.value]" :value="true">Specify:</mercur-radio>
                    <div class="configuration__input">
                        <template v-if="specifyingFields[field.value]">
                            <template v-if="field.type === 'number'">
                                <mercur-input :data-test="`${field.selectLabel}fieldTypeNumber`" v-model="details[field.value]">
                                    {{field.title}}
                                </mercur-input>
                            </template>
                            <template v-if="field.type === 'select'">
                                <pretty-select
                                    :data-test="`${field.selectLabel}fieldTypeSelect`"
                                    :placeholder="field.title"
                                    :label="field.selectLabel"
                                    :options="available[field.selectKey]"
                                    v-model="details[field.value]"
                                    :reduce="entry => entry[field.reduceKey]"
                                ></pretty-select>
                            </template>
                            <template v-if="field.type === 'mercurAttribute'">
                                <mercur-attribute-selector
                                    :data-test="`${field.selectLabel}fieldTypeAttribute`"
                                    v-model="selectedAttribute"
                                    :attributes="available['attributes']"
                                    :initial-attribute-name="null"
                                    :initial-attribute-option="null"
                                ></mercur-attribute-selector>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div>
                <mercur-input data-test="fieldTypeNumberMinutesToSubtract" v-model.number="details.minutes" type="number">
                    Minutes to subtract
                </mercur-input>
            </div>
        </div>
        <div class="position-relative">
            <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
        </div>
        <template slot="footer">
            <mercur-button data-test="CutOffTimesConfigurationCloseButton" class="btn" @click="localDialogActive = false">
                Close
            </mercur-button>
            <mercur-button data-test="CutOffTimesConfigurationSaveButton" class="btn btn-yellow" @click="saveCutOffTimeConfiguration" :disabled="$v.$invalid || loading">
                Save
            </mercur-button>
        </template>
    </mercur-dialog>
</template>

<script>
import regions from 'country-region-data'
import { validationMixin } from 'vuelidate'
import {
    required,
} from 'vuelidate/lib/validators'
import CONFIG from '@root/config'
import PrettySelect from '../utils/PrettySelect'
import MercurAttributeSelector from '../attributes/MercurAttributeSelector'

export default {
    name: 'CutOffTimesConfigurationPopup',
    props: ['dialogActive', 'cutOffTimeConfigurationDetails', 'available'],
    components: { MercurAttributeSelector, PrettySelect },
    mixins: [validationMixin],
    data () {
        return {
            availableCountries: regions,
            loading: false,
            details: null,
            fields: CONFIG.CUT_OFF_TIME_ADJUSTMENT.FIELDS,
            saveUrl: CONFIG.API.ROUTES.CUT_OFF_TIME_ADJUSTMENT.ADD,
            specifyingFields: {},
            selectedAttribute: null,
        }
    },
    computed: {
        localDialogActive: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
    },
    validations: {
        details: {
            minutes: {
                required,
            },
        },
    },
    methods: {
        saveCutOffTimeConfiguration () {
            const url = this.saveUrl
            this.addJob(url)
            this.loading = true
            const payload = {
                ...this.details,
                supplierId: this.supplierId,
            }

            this.$api.post(this.saveUrl, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Cut off time adjustment successfully added.`,
                })
                this.$emit('configuration-added', this.details)
                this.dialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving cut off time adjustment failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },

        handleDialogOpened () {
            this.fields.forEach(field => {
                this.$set(this.specifyingFields, field.value, false)
            })

            this.$set(this, 'details', {
                ...this.cutOffTimeConfigurationDetails,
            })

            this.$v.$reset()
        },
    },
    watch: {
        cutOffTimeConfigurationDetails: {
            handler () {
                this.handleDialogOpened()
            },
            deep: true,
            immediate: true,
        },
        selectedAttribute (value) {
            this.$set(this.details, 'attributeName', value.attributeName)
            this.$set(this.details, 'attributeOption', value.attributeOption)
        },
    },
    created () {
        this.handleDialogOpened()
    },
}
</script>
<style lang="scss" scoped>
    .pretty-select-error {
        position: absolute;
        transform: translate(0, -100%);
        font-size: 12px;
        letter-spacing: .01em;
    }
    .configuration {
        margin-bottom: 15px;
        border-bottom: 1px solid #dedede;

        &__field {
            display: flex;
            min-height: 76px;
        }
        &__radio {
            align-items: center;
        }
        &__input {
            flex: 1 0 auto;
            max-width: 600px;
        }
        &--indent {
            $indent: 15px;
            &1 {
                margin-left: 1 * $indent;
            }
            &2 {
                margin-left: 2 * $indent;
            }
            &3 {
                margin-left: 3 * $indent;
            }
            &4 {
                margin-left: 4 * $indent;
            }
            &5 {
                margin-left: 5 * $indent;
            }
            &6 {
                margin-left: 6 * $indent;
            }
        }
    }
</style>
