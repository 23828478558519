<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getAllCutOffTimeConfiguration')" class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Cut Off Times
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('SupplierCentral/addCutOffTimeConfiguration')" @click="openCutOffTimeConfiguration(null)" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    Add cut off time adjustment
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            :quickSearch="filters.search"
        ></data-table>
        <cut-off-times-configuration-popup
            :dialog-active.sync="cutOffTimeConfigurationPopupActive"
            :cut-off-time-configuration-details="cutOffTimeConfigurationDetails"
            @configuration-added="$refs.grid.refreshGrid()"
            :available="{
                facilities: availableFacilities,
                serviceLevels: availableServiceLevels,
                products: availableProducts,
                attributes: availableAttributes,
                countries: availableCountries,
            }"
        ></cut-off-times-configuration-popup>

        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <div slot="header">
                <h3>Are you sure you want to remove this cut off time configuration?</h3>
            </div>
            <div slot="default">
                It is not possible to recover the cut off time configuration after deleting.
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="deleteCutOffTime(cutOffTimeConfigurationDetails)">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import regions from 'country-region-data'
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import CutOffTimesConfigurationPopup from '../../components/cut_off_times/CutOffTimesConfigurationPopup'

export default {
    name: 'CutOffTimesOverview',
    components: { CutOffTimesConfigurationPopup, DataTable, GridHeader },
    data () {
        return {
            cutOffTimeConfigurationPopupActive: false,
            cutOffTimeConfigurationDetails: {},
            deleteConfirmationDialogActive: false,
            availableServiceLevels: null,
            availableFacilities: null,
            availableProducts: null,
            availableAttributes: null,
            availableCountries: regions,
            options: {
                columns: {
                    'ProductHash': {
                        field: 'productHash',
                    },
                    'Country': {
                        field: 'countryCode',
                    },
                    'Minutes': {
                        field: 'minutes',
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        hide: () => {
                            return !this.isAllowedTo('SupplierCentral/removeCutOffTimeConfiguration')
                        },
                        onClick: ({ data }) => this.openDeleteConfirmDialog(data),
                    },
                ],

                quickSearchColumns: ['agreementName'],
            },
            url: CONFIG.API.ROUTES.CUT_OFF_TIME_ADJUSTMENT.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },
    methods: {
        getSupplierIntegrationDetailRoute (params) {
            return {
                name: 'SupplierIntegrationDetail',
                params: {
                    supplierIntegrationConfigId: params.supplierIntegrationConfigId,
                },
            }
        },
        openCutOffTimeConfiguration (params = null) {
            this.cutOffTimeConfigurationDetails = params || {
                supplierId: null,
                countryCode: null,
            }
            this.cutOffTimeConfigurationPopupActive = true
        },
        openDeleteConfirmDialog (data) {
            this.cutOffTimeConfigurationDetails = data
            this.deleteConfirmationDialogActive = true
        },
        deleteCutOffTime () {
            this.cutOffTimeConfigurationPopupActive = false
            this.loading = true
            const jobName = JSON.stringify(this.localConfiguration)

            this.addJob(jobName)
            const url = CONFIG.API.ROUTES.CUT_OFF_TIME_ADJUSTMENT.REMOVE
                .replace('{cutOffTimeConfigurationId}', this.cutOffTimeConfigurationDetails.cutOffTimeConfigurationId)

            this.$api.post(url, this.cutOffTimeConfigurationDetails).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Cut off time configuration deleted.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Deleting cut off time configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
    },
    created () {
        this.$store.dispatch('carriers/fetchAllServiceLevels').then(data => {
            this.availableServiceLevels = data.data.items
        })

        this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId).then(data => {
            this.availableFacilities = data.filter(location => location.locationType === 'FACILITY')

            this.availableFacilities.forEach(facility => {
                this.$store.dispatch('suppliers/fetchLocationServiceLevels', {
                    supplierId: this.supplierId,
                    locationId: facility.locationId,
                    countryCode: facility.countryCode,
                }).then(facilityServiceLevels => {
                    this.$set(this, 'availableServiceLevels', facilityServiceLevels)
                })
            })
        })

        const productsUrl = CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', this.supplierId)
        this.$api.post(productsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.availableProducts = data.data.items
        })

        const mercurAttributesUrl = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST
        this.addJob(mercurAttributesUrl)
        this.$api.post(mercurAttributesUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.availableAttributes = data.data.items.flatMap(attribute => attribute.attributeName)
        }).finally(() => {
            this.finishJob(mercurAttributesUrl)
        })
    },
}
</script>
